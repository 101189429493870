<template>
  <div>
    <div class="about-page">
      <div class="round-right"></div>
      <div class="round-left"></div>
      <div class="container">
        <div class="row about-us-wrapper">
          <div class="col">
            <div class="services-block-title">
              О <span>компании</span>
            </div>
            <div class="about-us-desc-dark">
              Мы казахстанская IT-компания, работаем на рынке с 2019 года, оказываем услуги кастомной high-end разработки.
            </div>
            <div class="about-us-desc" style="margin-top: 20px">
              Создаем удобные сайты, web-сервисы, мобильные приложения.
            </div>
            <div class="about-us-contacts">
              <a class="contact-email">
                it@ts-technology.kz
              </a>
              <a class="contact-telegram">
                +7 706 708 0424
              </a>
              <a class="contact-address">
                Республика Казахстан, г. Алматы, пр-т Абая 68/74
              </a>
            </div>
          </div>
          <div class="col">
            <div class="workers-img" :style="{ 'background-image': `url('./css/images/about-back.png')` }">

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="section-title" style="padding-bottom: 20px">
          Наши <span>кейсы</span>
        </div>
        <div class="section-text">
          Исторически сложилось, что наши сильные стороны - это:
        </div>
        <div class="competences-block">
          <div class="competence-item">
            <div class="competence-logo" :style="{ 'background-image': `url('./css/images/img_compet_1.svg')` }">

            </div>
            <div class="competence-title">
              Разработка цифровых систем
            </div>
          </div>
          <div class="competence-item">
            <div class="competence-logo" :style="{ 'background-image': `url('./css/images/img_compet_2.svg')` }">

            </div>
            <div class="competence-title">
              ed-tech и fintech проекты
            </div>
          </div>
          <div class="competence-item">
            <div class="competence-logo" :style="{ 'background-image': `url('./css/images/img_compet_3.svg')` }">

            </div>
            <div class="competence-title">
              Автоматизация бизнеса
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="section-title" style="padding-bottom: 20px">
          Как <span>мы работаем</span>
        </div>
        <div class="steps-block">
          <div class="step-item box-shadow">
            <div class="step-logo" :style="{ 'background-image': `url('./css/images/step-1.svg')` }">

            </div>
            <div class="competence-title">
              Сбор данных
            </div>
            <div class="step-text">
              Изучаем поставленную задачу, определяем решение, методы и технологии реализации. Согласовываем все детали, заключаем договор и приступаем к разработке
            </div>
          </div>
          <div class="step-item box-shadow">
            <div class="step-logo" :style="{ 'background-image': `url('./css/images/step-2.svg')` }">

            </div>
            <div class="competence-title">
              Разработка
            </div>
            <div class="step-text">
              Разрабатываем под ключ, от проектирования проекта и дизайна до вывода в продакшн. Готовый функционал демонстрируется по мере готовности
            </div>
          </div>
          <div class="step-item box-shadow">
            <div class="step-logo" :style="{ 'background-image': `url('./css/images/step-3.svg')` }">

            </div>
            <div class="competence-title">
              Запуск
            </div>
            <div class="step-text">
              Размещаем сайты на серверах заказчика, а приложения в кабинетах Play Market и App Store. Передаем исходный
              код проекта
            </div>
          </div>
          <div class="step-item box-shadow">
            <div class="step-logo" :style="{ 'background-image': `url('./css/images/step-4.svg')` }">

            </div>
            <div class="competence-title">
              Поддержка
            </div>
            <div class="step-text">
              Не бросаем после завершения разработки. Следим за работой системы, исправляем баги, консультируем по вопросам, обучаем работе с системой
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="section-title" style="padding-bottom: 20px">
          Условия <span>работы</span>
        </div>
        <div class="conditions-block">
          <div class="condition-item box-shadow">
            <div class="condition-item-number">1</div>
            <div class="step-logo" :style="{ 'background-image': `url('./css/images/img_cond_1.svg')` }">

            </div>
            <div class="competence-title text-center">
              Придерживаемся здравого смысла
            </div>
          </div>
          <div class="condition-item box-shadow">
            <div class="condition-item-number">2</div>
            <div class="step-logo" :style="{ 'background-image': `url('./css/images/img_cond_2.svg')` }">

            </div>
            <div class="competence-title text-center">
              Партнерский подход при работе над проектом
            </div>
          </div>
          <div class="condition-item box-shadow">
            <div class="condition-item-number">3</div>
            <div class="step-logo" :style="{ 'background-image': `url('./css/images/img_cond_3.svg')` }">

            </div>
            <div class="competence-title text-center">
              High-end разработка по честным ценам
            </div>
          </div>
          <div class="condition-item box-shadow">
            <div class="condition-item-number">4</div>
            <div class="step-logo" :style="{ 'background-image': `url('./css/images/img_cond_4.svg')` }">

            </div>
            <div class="competence-title text-center">
              Не выполняем работу бесплатно
            </div>
          </div>
          <div class="condition-item box-shadow">
            <div class="condition-item-number">5</div>
            <div class="step-logo" :style="{ 'background-image': `url('./css/images/img_cond_5.svg')` }">

            </div>
            <div class="competence-title text-center">
              Не участвуем в сомнительных проектах
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'About',
}
</script>
<style>

</style>
